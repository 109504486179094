import { Alert, background, Box, Button, ButtonGroup, Checkbox, Container, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, useColorMode, useColorModePreference, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/staking.json"

import { ChevronDownIcon } from "@chakra-ui/icons";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = useMemo(() => new ethers.Contract('0xD7e87e99D70651f2579359CcA5f3596CbF3d9a1E', abi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [nfts, setNfts] = useState([])
    const [selectedJoes, setSelectedJoes] = useState([])
    const [selectedApes, setSelectedApes] = useState([])
    const [selectedRug, setSelectedRug] = useState([])
    const [stakedJoe, setStakedJoes] = useState([])
    const [stakedApe, setStakedApes] = useState([])
    const [stakedRugs, setStakedRug] = useState([])
    const [joeRewards, setJoeRewards] = useState(0)
    const [apeRewards, setApeRewards] = useState(0)
    const [rugRewards, setRugRewards] = useState(0)

    const handleError = (err) => {
        toast({
            title: "Error",
            description: err.reason || err.message,
            status: "error",
            duration: 9000,
            isClosable: true,
        })
    }

    const connect = useCallback(async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }, [provider, signer])

    const stakedJoes = useCallback(async () => {
        const staked = await contract.getStakedTokens('0x0F480081666B32F940Fb8f450AB5e3695689CF97', address)
        const number = staked.map((stake) => stake.toString())
        return number
    }, [address, contract])

    const stakedApes = useCallback(async () => {
        const staked = await contract.getStakedTokens('0xa562c2a71153C894F498345E9F007B39d487FEB5', address)
        const number = staked.map((stake) => Number(stake._hex, 16))
        return number
    }, [address, contract])

    const stakedRug = useCallback(async () => {
        const staked = await contract.getStakedTokens('0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1', address)
        const number = staked.map((stake) => Number(stake._hex, 16))
        return number
    }, [address, contract])

    const getAllRewards = useCallback(async () => {
        setJoeRewards((await contract.getUserRewards('0x0F480081666B32F940Fb8f450AB5e3695689CF97', address)).toString())
        setApeRewards((await contract.getUserRewards('0xa562c2a71153C894F498345E9F007B39d487FEB5', address)).toString())
        setRugRewards((await contract.getUserRewards('0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1', address)).toString())

    }, [address, contract])


    useEffect(() => {
        connect()
        constants.changeNetwork(43114)
        getAllRewards()
        stakedJoes().then((staked) => {
            setStakedJoes(staked)
        })
        stakedApes().then((staked) => {
            setStakedApes(staked)
        })
        stakedRug().then((staked) => {
            setStakedRug(staked)
        })
    }, [address, connect, getAllRewards, signer, stakedApes, stakedJoes, stakedRug])

    useMemo(() => {
        signer.getAddress().then((address) => {//&token_addresses%5B0%5D=0x0F480081666B32F940Fb8f450AB5e3695689CF97&token_addresses%5B1%5D=0xa562c2a71153C894F498345E9F007B39d487FEB5&token_addresses%5B2%5D=0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1
            fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses%5B0%5D=0x0F480081666B32F940Fb8f450AB5e3695689CF97&token_addresses%5B1%5D=0xa562c2a71153C894F498345E9F007B39d487FEB5&token_addresses%5B2%5D=0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nft) => {
                nft.json().then(e => {
                    setNfts(e.result)
                })

            })
        })
    }, [signer])

    const stakeJoes = async () => {
        const tx = await contract.stakeMultiple('0x0F480081666B32F940Fb8f450AB5e3695689CF97', selectedJoes.map((joe) => joe.token_id)).catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Staked",
            description: "Your GIGA JOES have been staked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const stakeApes = async () => {
        const tx = await contract.stakeMultiple('0xa562c2a71153C894F498345E9F007B39d487FEB5', selectedApes.map((ape) => ape.token_id)).catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Staked",
            description: "Your Apelanche have been staked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }


    const stakeRug = async () => {
        const tx = await contract.stakeMultiple('0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1', selectedRug.map((rug) => rug.token_id)).catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Staked",
            description: "Your TEST-RUG have been staked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const unstakeJoes = async () => {
        const tx = await contract.unstakeAll('0x0F480081666B32F940Fb8f450AB5e3695689CF97').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Unstaked",
            description: "Your GIGA JOES have been unstaked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const unstakeApes = async () => {
        const tx = await contract.unstakeAll('0xa562c2a71153C894F498345E9F007B39d487FEB5').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Unstaked",
            description: "Your Apelanche have been unstaked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const unstakeRug = async () => {
        const tx = await contract.unstakeAll('0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Unstaked",
            description: "Your TEST-RUG have been unstaked",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const claimJoes = async () => {
        const tx = await contract.claimMultiple('0x0F480081666B32F940Fb8f450AB5e3695689CF97').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Claimed",
            description: "Your GIGA JOES reweards have been claimed",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const claimApes = async () => {
        const tx = await contract.claimMultiple('0xa562c2a71153C894F498345E9F007B39d487FEB5').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Claimed",
            description: "Your Apelanche rewards have been claimed",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const claimRug = async () => {
        const tx = await contract.claimMultiple('0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1').catch(handleError)
        const rc = await tx.wait()
        console.log(rc)
        toast({
            title: "Claimed",
            description: "Your TEST-RUG rewards have been claimed",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }


    useColorMode().colorMode = 'dark'
    //console.log(nfts)

    return (

        <>
            <Container maxW="container.xl" mt="10">
                <Heading as="h1" size="2xl" mb="10">Stake your NFTs</Heading>
                <Button onClick={connect} mb="10">{address ? 'Connected as: ' + shorten(address) : 'Connect'}</Button>
                <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                    <Box border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'} p='1'>
                        <Heading as="h2" size="xl" mb="5">GIGA JOES</Heading>
                        <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                            {nfts.filter((nft) => nft.token_address === '0x0F480081666B32F940Fb8f450AB5e3695689CF97'.toLowerCase()).map((nft) => {
                                const metadtata = JSON.parse(nft.metadata)
                                const staked = stakedJoe.includes(nft.token_id)
                                return (
                                    <Box key={nft.token_id} border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'}>
                                        <Image src={metadtata.image.replace("ipfs://", "https://gateway.ipfs.io/ipfs/")} />
                                        <Text>{metadtata.name}</Text>
                                        {staked && <Text color='teal'>Staked</Text>}
                                        <Checkbox isChecked={selectedJoes.some((joe) => joe.token_id === nft.token_id)} onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedJoes([...selectedJoes, nft])
                                            } else {
                                                setSelectedJoes(selectedJoes.filter((joe) => joe.token_id !== nft.token_id))
                                            }
                                        }} />
                                    </Box>
                                )
                            })}
                        </SimpleGrid>
                        <Text>GIGAJOE rewards: {ethers.utils.formatEther(joeRewards)}</Text>
                        <ButtonGroup mt="5">
                            <Button onClick={stakeJoes} disabled={selectedJoes.length === 0}>Stake</Button>
                            <Button onClick={unstakeJoes}>UnstakeAll</Button>
                            <Button onClick={claimJoes}>Claim</Button>
                        </ButtonGroup>
                    </Box>
                    <Box border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'} p='1'>
                        <Heading as="h2" size="xl" mb="5">Apelanche</Heading>
                        <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                            {nfts.filter((nft) => nft.token_address === '0xa562c2a71153C894F498345E9F007B39d487FEB5'.toLowerCase()).map((nft) => {
                                const metadtata = JSON.parse(nft.metadata)
                                const staked = stakedApe.includes(Number(nft.token_id))
                                return (
                                    <Box key={nft.token_id} border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'}>
                                        <Image src={metadtata.image.replace("ipfs://", "https://gateway.ipfs.io/ipfs/")} />
                                        <Text>{metadtata.name}</Text>
                                        {staked && <Text color='teal'>Staked</Text>}
                                        <Checkbox isChecked={selectedApes.some((ape) => ape.token_id === nft.token_id)} onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedApes([...selectedApes, nft])
                                            } else {
                                                setSelectedApes(selectedApes.filter((ape) => ape.token_id !== nft.token_id))
                                            }
                                        }} />
                                    </Box>
                                )
                            })}
                        </SimpleGrid>
                        <Text>Apelanche rewards: {ethers.utils.formatEther(apeRewards)}</Text>
                        <ButtonGroup mt="5"> 
                            <Button onClick={stakeApes} disabled={selectedApes.length === 0}>Stake</Button>
                            <Button onClick={unstakeApes}>UnstakeAll</Button>
                            <Button onClick={claimApes}>Claim</Button>
                        </ButtonGroup>
                    </Box>
                    <Box border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'} p='1'>
                        <Heading as="h2" size="xl" mb="5">TEST-RUG</Heading>
                        <SimpleGrid columns={[1, 2, 3]} spacing="40px">
                            {nfts.filter((nft) => nft.token_address === '0xa4b6cbD006eB1E53990a4654156A32Da87dc1cD1'.toLowerCase()).map((nft) => {
                                const metadtata = JSON.parse(nft.metadata)
                                const staked = stakedRugs.includes(Number(nft.token_id))
                                return (
                                    <Box key={nft.token_id} border='1px' rounded={'2xl'} overflow='hidden' textAlign={'center'}>
                                        <Image src={metadtata.image.replace("ipfs://", "https://gateway.ipfs.io/ipfs/")} />
                                        <Text>{metadtata.name}</Text>
                                        {staked && <Text color='teal'>Staked</Text>}
                                        <Checkbox isChecked={selectedRug.some((rug) => rug.token_id === nft.token_id)} onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedRug([...selectedRug, nft])
                                            } else {
                                                setSelectedRug(selectedRug.filter((rug) => rug.token_id !== nft.token_id))
                                            }
                                        }} />
                                    </Box>
                                )
                            })}
                        </SimpleGrid>
                        <Text>TEST-RUG rewards: {ethers.utils.formatEther(rugRewards)}</Text>
                        <ButtonGroup mt="5">
                            <Button onClick={stakeRug} disabled={selectedRug.length === 0}>Stake</Button>
                            <Button onClick={unstakeRug}>UnstakeAll</Button>
                            <Button onClick={claimRug}>Claim</Button>
                        </ButtonGroup>
                    </Box>
                </SimpleGrid>
            </Container>

        </>

    )

}
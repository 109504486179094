import { GlobalStyle, theme, useColorModeValue, useStyleConfig } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";


import Home from "./pages/home";



function App() {

  return (
    <>
      <Routes>
        <Route path="*" element={<Home/>}/>
      </Routes>
    </>
  );
}

export default App;
